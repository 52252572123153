import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import CanalIntake from './pages/Utility/CanalIntake';
import ChemicalArea from './pages/Utility/ChemicalArea';
import Clearator from './pages/Utility/Clearator';
import Reservoir from './pages/Utility/Reservoir';
import ElevatedTank from './pages/Utility/ElevatedTank';
import PowerMeter from './pages/Utility/PowerMeter';
import ReportCanalIntake from './pages/Report_Analys/CanalIntake';
import ReportChemicalArea from './pages/Report_Analys/ChemicalArea';
import ReportClearator from './pages/Report_Analys/Clearator';
import ReportReservoir from './pages/Report_Analys/Reservoir';
import ReportElevatedTank from './pages/Report_Analys/ElevatedTank';
import ReportPowerMeter from './pages/Report_Analys/PowerMeter';
import ReportBackWash from './pages/Report_Analys/BackWash';
import ReportRinseDrain from './pages/Report_Analys/RinseDrain';
import ReportProductionTime from './pages/Report_Analys/ProductionTime';
import UpdateProductionTime from './pages/Report_Analys/UpdateProductionTime';
import UpdateStockOpname from './pages/Report_Analys/UpdateStockOpname';
import BmalChemicalArea from './pages/Setting_BMAL/ChemicalArea';
import BmalReservoir from './pages/Setting_BMAL/Reservoir';
import BmalElevatedTank from './pages/Setting_BMAL/ElevatedTank';
import Faq from './pages/Admin_Panel/Faq';
import User from './pages/Admin_Panel/User';
import HelpFaq from './pages/Help/Faq';
import HelpTerm from './pages/Help/TermOfUse';
import UserLog from './pages/Admin_Panel/UserLog';
import { Routes, Route, Outlet } from 'react-router-dom';
import BackWash from './pages/Utility/BackWash';
import RinseDrain from './pages/Utility/RinseDrain';
import AllSystem from './pages/Report_Analys/AllSystem';
import StockOfName from './pages/Report_Analys/StockOfName';
import ProductionTime from './pages/Utility/ProductionTime';
import Sidebar from './components/Sidebar';
import EarlyAllSystme from './pages/EarlyWarning/AllSystme';
import EarlyCanalIntake from './pages/EarlyWarning/CanalIntake';
import EarlyChemicalArea from './pages/EarlyWarning/ChemicalArea';
import EarlyClearator from './pages/EarlyWarning/Clearator';
import EarlyElevatedTank from './pages/EarlyWarning/ElevatedTank';
import EarlyPowerMeter from './pages/EarlyWarning/PowerMeter';
import EarlyReservoir from './pages/EarlyWarning/Reservoir';
import EarlyStockOfName from './pages/EarlyWarning/StockOfName';
import ProtectedRoute from './pages/ProtectedRoute';
import { useEffect } from 'react';
import MyProfile from './pages/MyProfile';
import Setting from './pages/Setting';
import CategoryFaq from './pages/Admin_Panel/CategoryFaq';
import DashboardTv from './pages/DashboardTv';
import DashboardTvs from './pages/DashboadTVs'
import Dashboard2 from './pages/dashboard2'
import Footer from './components/Footer';
import Test from './pages/coba/test'
import Elektrik from './pages/Report_Analys/elektrikal'
import ReportElectrical from './pages/Report_Analys/Electrical'
import ReportChart from './pages/Report_Analys/Chart'
import GetHoursSO from './pages/Report_Analys/gethours/stockopname'
import GetHoursAS from './pages/Report_Analys/gethours/allSystems'
import GetHoursEl from './pages/Report_Analys/gethours/electrical'
// import DashboardTvs from './pages/DashboardTvs';

const SidebarLayout = () => (
  <>
    <Sidebar />
    <div className="main-content" id="panel">
      <Outlet />
      <Footer />
    </div>
  </>
);


function App() {
  return (
    <div id="wrap">
      <div className="main-content" id="panel">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<SidebarLayout />}>
              <Route path="utility/dashboard" element={<Dashboard2 />} />
              <Route path="reportAnalysis/gethours/stockopname" element={<GetHoursSO />} />
              <Route path="reportAnalysis/gethours/electrical" element={<GetHoursEl/>} />
              <Route path="reportAnalysis/electrical" element={<ReportElectrical/>} />
              <Route path="reportAnalysis/chart" element={<ReportChart/>} />
              <Route path="reportAnalysis/elektrik" element={<Elektrik/>} />
              <Route path="reportAnalysis/gethours/allSystem" element={<GetHoursAS />} />
              <Route path="utility/dashboardTV1" element={<DashboardTv />} />
              <Route path="utility/dashboardTV2" element={<DashboardTvs />} />
              <Route path="utility/dashboard2" element={<Dashboard />} />
              <Route path="/utility/canalIntake" element={<CanalIntake />} />
              <Route path="/utility/cemicalArea" element={<ChemicalArea />} />
              <Route path="/utility/clearator" element={<Clearator />} />
              <Route path="/utility/productionTime" element={<ProductionTime />} />
              <Route path="/utility/reservoir" element={<Reservoir />} />
              <Route path="/utility/elevatedTank" element={<ElevatedTank />} />
              <Route path="/utility/powerMeter" element={<PowerMeter />} />
              <Route path="/utility/backwash" element={<BackWash />} />
              <Route path="/utility/rinseDrain" element={<RinseDrain />} />
              <Route path="/reportAnalysis/allSystem" element={<AllSystem />} />
              <Route path="/reportAnalysis/productionTime" element={<AllSystem />} />
              <Route path="/reportAnalysis/productionTime/:id" element={<UpdateProductionTime />} />
              <Route path="/reportAnalysis/stockOpname" element={<StockOfName />} />
              <Route path="/reportAnalysis/stockOpname/:id" element={<UpdateStockOpname />} />
              <Route path="/reportAnalysis/canalIntake" element={<ReportCanalIntake />} />
              <Route path="/reportAnalysis/chemicalArea" element={<ReportChemicalArea />} />
              <Route path="/reportAnalysis/clearator" element={<ReportClearator />} />
              <Route path="/reportAnalysis/reservoir" element={<ReportReservoir />} />
              <Route path="/reportAnalysis/elevatedTank" element={<ReportElevatedTank />} />
              <Route path="/reportAnalysis/powerMeter" element={<ReportPowerMeter />} />
              <Route path="/reportAnalysis/productionTime" element={<ReportProductionTime />} />
              <Route path="/reportAnalysis/backwash" element={<ReportBackWash />} />
              <Route path="/reportAnalysis/rinseDrain" element={<ReportRinseDrain />} />
              <Route path="/earlyWarningSystem/AllSystme" element={<EarlyAllSystme />} />
              <Route path="/earlyWarningSystem/canalIntake" element={<EarlyCanalIntake />} />
              <Route path="/earlyWarningSystem/chemicalArea" element={<EarlyChemicalArea />} />
              <Route path="/earlyWarningSystem/clearator" element={<EarlyClearator />} />
              <Route path="/earlyWarningSystem/elevatedTank" element={<EarlyElevatedTank />} />
              <Route path="/earlyWarningSystem/powerMeter" element={<EarlyPowerMeter />} />
              <Route path="/earlyWarningSystem/reservoir" element={<EarlyReservoir />} />
              <Route path="/earlyWarningSystem/stockOfName" element={<EarlyStockOfName />} />
              <Route path="/settingBMAL/chemicalArea" element={<BmalChemicalArea />} />
              <Route path="/settingBMAL/reservoir" element={<BmalReservoir />} />
              <Route path="/settingBMAL/elevatedTank" element={<BmalElevatedTank />} />
              <Route path="/adminPanel/faq" element={<Faq />} />
              <Route path="/adminPanel/categoryFaq" element={<CategoryFaq />} />
              <Route path="/adminPanel/user" element={<User />} />
              <Route path="/adminPanel/userLog" element={<UserLog />} />
              <Route path="/help/faq" element={<HelpFaq />} />
              <Route path="/help/termOfUse" element={<HelpTerm />} />
              <Route path="/account/myProfile" element={<MyProfile />} />
              <Route path="/account/setting" element={<Setting />} />
              <Route path="/coba/test" element={<Test />} />
            </Route>
          </Route>
        </Routes>
      </div>

    </div>
  );
}

export default App;
