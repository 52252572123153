import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import API from "../api/base_url";

const Header = ({ setRoleName }) => {
  const history = useNavigate();
  const [displayName, setDisplayName] = useState("") 
  const [displayPhoto, setDisplayPhoto] = useState("") 

  
    let token = localStorage.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
      API.get("dashboard/account/profile", { headers })
      .then(({ data:content }) => {
        setDisplayName(content.data.name)
        setDisplayPhoto(content.data.photo)
        if (setRoleName) {
          setRoleName(content.data.role_name.toLowerCase())
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

  const logout = () => {
    localStorage.removeItem('token')
      history('/');
    }
    
  return (
    <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* Search form */}
          
          {/* Navbar links */}
          <ul className="navbar-nav align-items-center ml-md-auto">
            <li className="nav-item d-xl-none">
              {/* Sidenav toggler */}
              <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </li>
          </ul>
          <ul className="navbar-nav align-items-center ml-auto ml-md-0 mt--1">
            <li className="nav-item dropdown">
              <a className="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="i" src={displayPhoto} />
                  </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm text-white font-weight-bold">{displayName}</span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to="/account/myProfile" className="dropdown-item">
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </Link>
                <div className="dropdown-divider" />
                <a onClick={()=> logout()} className="dropdown-item">
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header