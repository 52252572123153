import React, { useEffect, useState } from "react";
import CardLTE from "../components/CardTVs_Components/CardLTE";
import API from "../api/base_url";
import HeaderDashboard from "../components/CardTVs_Components/HeaderDashboard";
import Helper from "../utility/helper.js"
import moment from 'moment'
import ReactSpinner from "../components/ReactSpinner";



const DashboardTvs = () => {
  // Card Info
  // const [powerMeter, setPowerMeter] = useState([]);
  const [productionTime, setProductionTime] = useState("00:00:00");
  const [lastUpdate, setlastUpdate] = useState("00:00:00");
  const [intakeArea, setIntakeArea] = useState(0);
  const [drainRinse, setDrainRinse] = useState(0);
  const [overflow, setoverFlow] = useState(0);
  const [backwashArea, setBackWashArea] = useState(0);
  const [clearatorArea, setClearatorArea] = useState(0);
  const [powermeterArea, setpowermeterArea] = useState([]);
  const [intake, setIntake] = useState([]);
  const [total, setTotal] = useState([]);

  const totalCurrentR = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.current_r) + parseFloat(powermeterArea[0].current_r) + parseFloat(powermeterArea[1].current_r)) : 0;
  const totalCurrentS = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.current_s) + parseFloat(powermeterArea[0].current_s) + parseFloat(powermeterArea[1].current_s)) : 0;
  const totalCurrentT = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.current_t) + parseFloat(powermeterArea[0].current_t) + parseFloat(powermeterArea[1].current_t)) : 0;
  const totalVoltageR = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.voltage_r) + parseFloat(powermeterArea[0].voltage_r) + parseFloat(powermeterArea[1].voltage_r)) : 0;
  const totalVoltageS = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.voltage_s) + parseFloat(powermeterArea[0].voltage_s) + parseFloat(powermeterArea[1].voltage_s)) : 0;
  const totalVoltageT = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.voltage_t) + parseFloat(powermeterArea[0].voltage_t) + parseFloat(powermeterArea[1].voltage_t)) : 0;
  const totalFrequency = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.frequency) + parseFloat(powermeterArea[0].frequency) + parseFloat(powermeterArea[1].frequency)) : 0;
  const totalWatt = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.watt) + parseFloat(powermeterArea[0].watt) + parseFloat(powermeterArea[1].watt)) : 0;
  const totalKwh = (intake && powermeterArea.length >= 2) ? 
    (parseFloat(intake.kwh) + parseFloat(powermeterArea[0].kwh) + parseFloat(powermeterArea[1].kwh)) : 0;
  //  Interface Info
  const [chemicalArea, setChemicalArea] = useState([]);
  const [reservoirArea, setReservoirArea] = useState({ balance: "", tank: [] });
  const [elevatedArea, setElevatedArea] = useState([]);
  const [success, setSuccess] = useState(false);
  // const [isLoading, setIsLoading] = useState(true)


  let token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getData = () => {
    API.get("/utility/dashboard-real", { headers })
      .then(({ data: content }) => {
        setProductionTime(content.data.production_time);
        setlastUpdate(content.data.last_update);
        // control room
        const filteredData = content.data.power_meter_area.filter(item => item.name === 'Stage 1 Power Meter 3 Phase' || item.name === 'Stage 2 Power Meter 3 Phase');
        setpowermeterArea(filteredData)
        // intake 
        const findIntake = content.data.power_meter_area.find(item => item.location === 'Intake')
        setIntake(findIntake)
        const findTotal = content.data.power_meter_area.find(item => item.location === 'Total')
        setTotal(findTotal)
        // setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {

      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [powermeterArea]);

  return (
    <div id="wrap" style={{ minHeight: "100vh" }}>
      {/* {isLoading ? <ReactSpinner /> : */}
      <div className="main-content" id="panel">
        <HeaderDashboard success={success} />
        <div>
          <div className="mx-4" style={{ minHeight: '50%' }}>
            <CardLTE
              // powerMeter={powerMeter}
              productionTime={productionTime}
              lastUpdate={lastUpdate}
            />
          </div>
        </div>
        <div className="">
          <div className="row position-relative jarak">
            <div className="col-lg-12">
              <div className="  p-2 ">
                {/* Old Coding */}
                {/* Coba */}
                {/* Control Room */}
                <div className=" col" style={{ marginBottom: '0 !important' }} >
                  {/* Nama  */}
                  <span className="text-darker">
                    Control Room
                  </span>
                  {
                    powermeterArea.map(item => (
                      <div key={item.name} className="row mt-2 d-flex no-gutters justify-content-center">
                        <div className="col-lg-12">
                          <div className="mt-2 bg-secondary py-2 px-4 shadowTailwind">
                            <span className="font-weight-bold  fontTV ">{item.name}</span>
                            {/* Monitor Menu */}
                            <div className="row">
                              {/* Voltage */}
                              <div className="col-lg-3">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Voltage</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.voltage_r}</span>
                                        <span className="fontTV">R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.voltage_s}</span>
                                        <span className="fontTV">S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.voltage_t}</span>
                                        <span className="fontTV">T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* EndVoltage */}
                              </div>

                              {/* EndVoltage */}
                              {/* Current */}
                              <div className="col-lg-3">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Current</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.current_r}</span>
                                        <span className="fontTV">R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.current_s}</span>
                                        <span className="fontTV">S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.current_t}</span>
                                        <span className="fontTV">T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndCurrent */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Frequency</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.frequency}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">KWH</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.kwh}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12  fontTV p-2 text-center ">
                                    <span className="text-white ">Watt</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{item.watt}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* Power stage 2 */}

                </div>
                {/* Intake */}
                <div style={{ marginTop: '0 !important' }} className=" col my-2 ">
                  {/* Nama  */}
                  <span className="text-darker my-4">
                    Intake
                  </span>
                  {/* Monitor Menu */}
                  {
                    intake ? (
                      <div className="row mt-2 d-flex no-gutters justify-content-center">
                        <div className="col-lg-12">
                          <div className="mt-2 bg-secondary py-2 px-4 shadowTailwind">
                            <span className="font-weight-bold  fontTV ">{intake.name}</span>
                            {/* Monitor Menu */}
                            <div className="row">
                              {/* Voltage */}
                              <div className="col-lg-3">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Voltage</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.voltage_r}</span>
                                        <span className="fontTV">R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.voltage_s}</span>
                                        <span className="fontTV">S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.voltage_t}</span>
                                        <span className="fontTV">T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* EndVoltage */}
                              </div>

                              {/* EndVoltage */}
                              {/* Current */}
                              <div className="col-lg-3">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Current</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.current_r}</span>
                                        <span className="fontTV">R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.current_s}</span>
                                        <span className="fontTV">S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.current_t}</span>
                                        <span className="fontTV">T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndCurrent */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Frequency</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.frequency}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">KWH</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.kwh}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12  fontTV p-2 text-center ">
                                    <span className="text-white ">Watt</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{intake.watt}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>data kosong</p>
                    )
                  }
                </div>
                {/* Total */}
                {
                  total ? (
                    <div className="col  my-2 position-relative">
                      <span className="text-darker">
                        Total
                      </span>
                      <div className="row mt-2 d-flex no-gutters justify-content-center">
                        <div className="col-lg-12">
                          <div className="mt-2 bg-secondary py-2 px-4 shadowTailwind">
                            <span className="font-weight-bold  fontTV ">{total.name}</span>
                            {/* Monitor Menu */}
                            <div className="row">
                              {/* Voltage */}
                              <div className="col-lg-3">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Voltage</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalVoltageR}</span>
                                        <span className="fontTV">R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalVoltageS}</span>
                                        <span className="fontTV">S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalVoltageT}</span>
                                        <span className="fontTV">T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* EndVoltage */}
                              </div>

                              {/* EndVoltage */}
                              {/* Current */}
                              <div className="col-lg-3">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Current</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalCurrentR}</span>
                                        <span className="fontTV">R-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalCurrentS}</span>
                                        <span className="fontTV">S-N</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0">
                                    <div className="bg-primaryPallete3 text-primary pt-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalCurrentT}</span>
                                        <span className="fontTV">T-N</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndCurrent */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">Frequency</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalFrequency}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12 fontTV p-2 text-center ">
                                    <span className="text-white ">KWH</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalKwh}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                              {/* Frequency */}
                              <div className="col-lg-2">
                                <div className="row bg-primaryPallete3  powerMeterThrepowermeterAreaayoutCardNoBorderRadius bg-red">
                                  <div className="col-lg-12  fontTV p-2 text-center ">
                                    <span className="text-white ">Watt</span>
                                  </div>
                                  {/* isi menu */}
                                  <div className="col-lg-12 p-0">
                                    <div className="bg-primaryPallete3 text-primary py-3">
                                      <div className="text-center d-flex p-2 flex-column">
                                        <span className="fontTV text-primary font-weight-bold">{totalWatt}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* EndFrequency */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  : (<p></p>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </div>
  );
};

export default DashboardTvs;
