import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../api/base_url";
import DatePicker from "../../components/DatePicker";
import Footer from "../../components/Footer";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Header from "../../components/Header";
import moment from 'moment-timezone';
import ExportListAllSystem from "../../components/ExportExcelAllSystem/ExportListAllSystem";
import ExportListStockOpname from "../../components/ExportExcelStockopname/ExportListStockOpname";
import ReactSpinner from "../../components/ReactSpinner";




const StockOfName = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start = moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

  const [site, setSite] = useState([]);
  const navigate = useNavigate();
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [datetime, setUpdateDatetime] = useState("");
  const [updateproduction_time, setUpdateproduction_time] = useState("");
  const [updatechemical_naocl_mas, setUpdatechemical_naocl_mas] = useState("");
  const [updatechemical_naocl_ppm, setUpdatechemical_naocl_ppm] = useState("");
  const [updatechemical_polymer_mas, setUpdatechemical_polymer_mas] = useState("");
  const [updatechemical_polymer_ppm, setUpdatechemical_polymer_ppm] = useState("");
  const [updatechemical_pac_mas, setUpdatechemical_pac_mas] = useState("");
  const [updatechemical_pac_ppm, setUpdatechemical_pac_ppm] = useState("");
  const [updateintake, setUpdateintake] = useState("");
  const [updateclearator, setUpdateclearator] = useState("");
  const [updatedrain, setUpdatedrain] = useState("");
  const [updatetower_1, setUpdatetower_1] = useState("");
  const [updatetower_2, setUpdatetower_2] = useState("");
  const [updatebackwash, setUpdatebackwash] = useState("");
  const [updatereservoir, setUpdatereservoir] = useState("");
  const [updateloses, setUpdateloses] = useState("");
  const [updateRole, setUpdateRole] = useState("");
  const [willUpdate, setWillUpdate] = useState("")
  
  
  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])
  

  const { id } = useParams();
  // const populationDataForUpdate = (id) => {
    
  // };

  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to Update this User?",
      showCancelButton: true,
      confirmButtonText: "Update",
    }).then((result) => {
      if (result.isConfirmed) {
        const loses = updateintake == 0 ? 0 : parseFloat(parseFloat(((Number(updatedrain) + Number(updateclearator) + Number(updatebackwash)) / Number(updateintake)) * 100).toFixed(2))
        const naocl_ppm = updateintake == 0 ? 0 : parseFloat(parseFloat((updatechemical_naocl_mas / updateintake) * 1000).toFixed(2))
        const polymer_ppm = updateintake == 0 ? 0 : parseFloat(parseFloat((updatechemical_polymer_mas / updateintake) * 1000).toFixed(2))
        const pac_ppm = updateintake == 0 ? 0 : parseFloat(parseFloat((updatechemical_pac_mas / updateintake) * 1000).toFixed(2))
        const payload = {
          datetime: datetime,
          production_time: Number(updateproduction_time),
          chemical_naocl_mas: Number(updatechemical_naocl_mas),
          chemical_naocl_ppm: Number(naocl_ppm),
          chemical_polymer_mas: Number(updatechemical_polymer_mas),
          chemical_polymer_ppm: Number(polymer_ppm),
          chemical_pac_mas: Number(updatechemical_pac_mas),
          chemical_pac_ppm: Number(pac_ppm),
          intake: Number(updateintake),
          clearator: Number(updateclearator),
          drain: Number(updatedrain),
          tower_1: Number(updatetower_1),
          tower_2: Number(updatetower_2),
          backwash: Number(updatebackwash),
          reservoir: Number(updatereservoir),
          loses: Number(loses),
        };
        if (
          !payload.production_time 
          ) {
            // !payload.chemical_naocl_mas ||
            // !payload.chemical_naocl_vol ||
            // !payload.chemical_polymer_mas ||
            // !payload.chemical_polymer_vol ||
            // !payload.chemical_pac_mas ||
            // !payload.chemical_pac_vol ||
            // !payload.intake ||
            // !payload.clearator ||
            // !payload.drain ||
            // !payload.tower_1 ||
            // !payload.tower_2 ||
            // !payload.backwash ||
            // !payload.reservoir ||
            // !payload.loses
            Swal.fire("Data is Required, please fill out the form", "", "error");
          } else {
          // console.log(payload);
          API.put(`/report/production/time/day?report_all_id=${id}`, payload, { headers })
            .then(({ data }) => {
              navigate(`/reportAnalysis/productionTime`);
              Swal.fire("User has updated successfully", "", "success");
            })
            .catch((err) => {
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getListData = () => {
    API.get(`report/stock/opname/day/${dateRangePickerValue}`, { headers })
      .then(({ data: content }) => {
        setData(content.data.stock_opname)
        setPending(false)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getListDetailData = (id) => {
    API.get(`report/stock/opname/hour/${id}`, { headers })
      .then(({ data: content }) => {
        setDetailData(content.data.production_time)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    API.get(`report/production/time/day/id?report_all_id=${id}`, { headers })
    .then(({ data: content }) => {
      setWillUpdate(content.data.id)
      setUpdateDatetime(content.data.datetime);
      setUpdateproduction_time(content.data.production_time);
      setUpdatechemical_naocl_mas(content.data.chemical_naocl_mas);
      setUpdatechemical_naocl_ppm(content.data.chemical_naocl_ppm);
      setUpdatechemical_polymer_mas(content.data.chemical_polymer_mas);
      setUpdatechemical_polymer_ppm(content.data.chemical_polymer_ppm);
      setUpdatechemical_pac_mas(content.data.chemical_pac_mas);
      setUpdatechemical_pac_ppm(content.data.chemical_pac_ppm);
      setUpdateintake(content.data.intake);
      setUpdateclearator(content.data.clearator);
      setUpdatedrain(content.data.drain);
      setUpdatetower_1(content.data.tower_1);
      setUpdatetower_2(content.data.tower_2);
      setUpdatebackwash(content.data.backwash);
      setUpdatereservoir(content.data.reservoir);
      setUpdateloses(content.data.loses);
      // setUpdateRole(Number(content.data.role_id))
    })
    .catch((err) => {
      console.log(err);
    });
    getSite()
    getListData()
  }, [])

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
      <div>
        <Header />
        {/* <BreadCumb pageRoot="Report & Analisis" name="Stock Opname" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" /> */}
        {/* <div className="container-fluid mt--6">
          <div className="card shadowTailwind">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="col-lg-6">
                    <label className="form-control-label textPallete">Site</label>
                    <select
                      className="form-control text-darker"
                      id="channel"
                      name="channel"
                      data-toggle="select"
                      disabled
                      value={1}
                    >
                      {site.length === 0 ? (
                        <></>
                      ) : (
                        site.map((el, idx) => (
                          <option value={el.id} key={idx}>
                            {el.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-9">
                        <label className="form-control-label textPallete">Date Range</label>
                        <DatePicker take={(val) => setDateRangePickerValueFunc(val)} takeTimeExcel={(val) => setDateRangePickerValueExcelFunc(val)} />
                      </div>
                      <div className="col-lg-3">
                        <label className="form-control-label textPallete">&nbsp;</label> <br />
                        <button className="btn bg-pallete text-white" onClick={() => handleFilterSubmit()}>
                          <span className="fa fa-search mr-2" /> Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


{/* // production_time -> production time
  // chemical_naocl_mas -> naocl
  // chemical_naocl_vol -> ppm naocl
  // chemical_polymer_mas -> polymer 
  // chemical_polymer_vol -> ppm polymer
  // chemical_pac_mas -> pac
  // chemical_pac_vol -> ppm pac
  // intake -> intake
  // clearator -> over sludge drain
  // drain -> drain/rinse
  // tower_1 -> tower 1
  // tower_2 -> tower 2
  // backwash -> backwash
  // reservoir -> resrevoir
  // loses -> loses production */}
        {/* page comtent */}
        <div className="row container-fluid mt-3">
          <div className="col-lg-12">
            <div className="card">
              <div className="d-flex justify-content-between card-header">
                <h4 className="text-darker">
                  Update
                </h4>
                {/* <ExportListStockOpname time={dateRangePickerDisplay} location={"Stock Opname"} data={data} /> */}
                <div className="form-group">
                  <label className="form-control-label">Production Time</label>
                  <input
                    type="text"
                    value={updateproduction_time}
                    onChange={(e)=> setUpdateproduction_time(e.target.value)}
                    className="form-control input-sm"
                  />
                </div>
              </div><br></br>
              <div className="col-lg-12">
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-control-label"><b>NaOCL</b></label>
                        </div>
                        <div className="col-lg-6">
                          <label className="form-control-label">TANK A</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-control-label"></label>
                        </div>
                        <div className="col-lg-6">
                          <label className="form-control-label">TANK B</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">NoCL</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">NoCL PPM</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_ppm}
                              onChange={(e)=> setUpdatechemical_naocl_ppm(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Polymer</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_polymer_mas}
                              onChange={(e)=> setUpdatechemical_polymer_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Polymer PPM</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_polymer_ppm}
                              onChange={(e)=> setUpdatechemical_polymer_ppm(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-control-label"><b>Polymer</b></label>
                        </div>
                        <div className="col-lg-6">
                          <label className="form-control-label">TANK A</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-control-label"></label>
                        </div>
                        <div className="col-lg-6">
                          <label className="form-control-label">TANK B</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">PAC</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_pac_mas}
                              onChange={(e)=> setUpdatechemical_pac_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">PAC PPM</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_pac_ppm}
                              onChange={(e)=> setUpdatechemical_pac_ppm(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Nilai Awal</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Nilai Akhir</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-control-label"><b>PAC</b></label>
                        </div>
                        <div className="col-lg-6">
                          <label className="form-control-label">TANK A</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-control-label"></label>
                        </div>
                        <div className="col-lg-6">
                          <label className="form-control-label">TANK B</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">NIlai Awal</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Nilai Akhir</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Nilai Awal</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="form-control-label">Nilai Akhir</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              value={updatechemical_naocl_mas}
                              onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                              className="form-control input-sm"
                            />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">CM </span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">PAC</label>
                        <input
                          type="text"
                          value={updatechemical_pac_mas}
                          onChange={(e)=> setUpdatechemical_pac_mas(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">PPM PAC</label>
                        <input
                          type="text"
                          value={updatechemical_pac_ppm}
                          onChange={(e)=> setUpdatechemical_pac_ppm(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Polymer</label>
                        <input
                          type="text"
                          value={updatechemical_polymer_mas}
                          onChange={(e)=> setUpdatechemical_polymer_mas(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">PPM Polymer</label>
                        <input
                          type="text"
                          value={updatechemical_polymer_ppm}
                          onChange={(e)=> setUpdatechemical_polymer_ppm(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Naocl</label>
                        <input
                          type="text"
                          value={updatechemical_naocl_mas}
                          onChange={(e)=> setUpdatechemical_naocl_mas(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">PPM Naocl</label>
                        <input
                          type="text"
                          value={updatechemical_naocl_ppm}
                          onChange={(e)=> setUpdatechemical_naocl_ppm(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Tower 1</label>
                        <input
                          type="text"
                          value={updatetower_1}
                          onChange={(e)=> setUpdatetower_1(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">Intake</label>
                        <input
                          type="text"
                          value={updateintake}
                          onChange={(e)=> setUpdateintake(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">Backwash</label>
                        <input
                          type="text"
                          value={updatebackwash}
                          onChange={(e)=> setUpdatebackwash(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">Over Sludge Drain</label>
                        <input
                          type="text"
                          value={updateclearator}
                          onChange={(e)=> setUpdateclearator(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Tower 2</label>
                        <input
                          type="text"
                          value={updatetower_2}
                          onChange={(e)=> setUpdatetower_2(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">Reservoir</label>
                        <input
                          type="text"
                          value={updatereservoir}
                          onChange={(e)=> setUpdatereservoir(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                      {/* <div className="form-group">
                        <label className="form-control-label">Loses Production</label>
                        <input
                          type="text"
                          value={updateloses}
                          onChange={(e)=> setUpdateloses(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div> */}
                      <div className="form-group">
                        <label className="form-control-label">Drain/Rinse</label>
                        <input
                          type="text"
                          value={updatedrain}
                          onChange={(e)=> setUpdatedrain(e.target.value)}
                          className="form-control input-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <button 
                  className="btn btn-warning mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=> handleUpdate()}
                  >
                    Edit
                  </button>
                  <button 
                  className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=> navigate('/reportAnalysis/productionTime')}
                  >
                    Cancel
                  </button>
              </div><br></br>
            </div>
          </div>
        </div>
        {/* Modal Update */}
        <div className="modal fade" id="exampleModalUpdate" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="modal-title mb-5" id="exampleModalLabel">
                        Update Remarks
                      </h4>
                    </div>
                    <div className="col-lg-12">
                      <form>
                        <div className="form-row align-items-center">
                          <div class="col-1">
                            <label for="exampleInputPassword1">Remarks</label>
                          </div>
                          <div className="col-11">
                            <textarea type="password" class="form-control" id="exampleInputPassword1" placeholder="Remaks" />
                          </div>
                        </div>
                        <br />
                        <button type="submit" class="btn w-100 btn-success">Simpan</button>
                      </form>
                    </div>
                  </div>
                  {/* <button
                    className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button> */}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="dynamicForm" />
              </div>
            </div>
          </div>
        </div>
        {/* End of Modal Update */}
      </div>
      {/* } */}
    </div>
  )
};


export default StockOfName;








