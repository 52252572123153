import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from 'moment';
import Swal from 'sweetalert2'




export default function ExportListAllSystem({ time, location, data }) {
  const tableRef = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Production Time - ${new Date().toLocaleDateString('id-ID')}`,
    sheet: "Production Time"
  });

  let handleSubmit = () => {
    onDownload()

    Toast.fire({
      icon: "success",
      title: "Data has exported",
    });
  }


  return (
    <div>
      <button hidden={data.length == 0} onClick={() => handleSubmit()} className="btn bg-pallete text-white"><span className="fa fa-download mr-2" /> Export excel </button>

      <table className="table table-bordered datatables" ref={tableRef} style={{ display: 'none' }}>
        <thead className="thead-light">
          <tr>
            <th colSpan={1} rowSpan={2}  style={{ textAlign: "center", verticalAlign: "middle" }}>No</th>
            <th colSpan={1} rowSpan={2}  style={{ textAlign: "center", verticalAlign: "middle" }}>Date </th>
            <th colSpan={1} rowSpan={2}>Production Time<br />(Hours)</th>
            <th colSpan={6} rowSpan={1} className="text-center "><b>Chemical Consumption</b></th>
            <th colSpan={1} rowSpan={2} className="text-center "  style={{ textAlign: "center", verticalAlign: "middle" }}><b>Intake (m<sup>3</sup>)</b></th>
            <th colSpan={4} rowSpan={1} className="text-center "><b>Rinse/Drain</b></th>
            <th colSpan={2} rowSpan={1} className="text-center "><b>Distribution Of Treated Water</b></th>
            <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}><b>Loses Production (m3)</b></th>
            <th colSpan={1} rowSpan={2} className="text-center "  style={{ textAlign: "center", verticalAlign: "middle" }}><b>REMARKS	</b></th>
            {/* <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}><b>Action</b></th> */}
          </tr>
          <tr>
            <th className="text-center ">PAC<br />(Kg)</th>
            <th className="text-center ">PPM PAC<br />(Kg)</th>
            <th className="text-center ">Polymer<br />(KG)</th>
            <th className="text-center ">PPM Polymer<br />(KG)</th>
            <th className="text-center ">Na0CI<br />(KG)</th>
            <th className="text-center ">PPM Na0CI<br />(KG)</th>
            <th className="text-center ">Drain/Rinse<br />(m3)</th>
            <th className="text-center ">Over Sludge Drain<br />(m3)</th>
            <th className="text-center ">Tower I<br />(m3)</th>
            <th className="text-center ">Tower II<br />(m3)</th>
            <th className="text-center ">Backwash<br />(m3)</th>
            <th className="text-center ">Reservoir<br />(m3)</th>
          </tr>
        </thead>
        <tbody>
          {data !== 0 && data.map((el, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{moment.utc(el.datetime).local().format('DD/MM/YYYY ')}</td>
              <td><span className="font-weight-bold">{el.production_time}</span> </td>
              <td className="text-center ">{el.chemical_pac_mas}</td>
              <td className="text-center ">{el.chemical_pac_ppm}</td>
              <td className="text-center ">{el.chemical_polymer_mas}</td>
              <td className="text-center ">{el.chemical_polymer_ppm}</td>
              <td className="text-center ">{el.chemical_naocl_mas}</td>
              <td className="text-center ">{el.chemical_naocl_ppm}</td>
              <td className="text-center ">{el.intake}</td>
              <td className="text-center ">{el.drain}</td>
              <td className="text-center ">{el.clearator}</td>
              <td className="text-center ">{el.tower_1}</td>
              <td className="text-center ">{el.tower_2}</td>
              <td className="text-center ">{el.backwash}</td>
              <td className="text-center ">{el.reservoir}</td>
              <td>{el.loses}</td>
              <td>-</td>
              {/* <td style={{ display: "flex", justifyContent: "center" }}>
                <button id="btn_edit" type="button" data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-danger" >Edit Remaks</button>
                <button id="btn_hours" className="ml-3 btn btn-sm bg-orange  text-white " ><a href={`/reportAnalysis/productionTime/${el.id}`} className="text-white">Update</a></button>
                <button id="btn_hours" className="ml-3 btn btn-sm bg-green  text-white " ><a href="/reportAnalysis/gethours/allSystem" className="text-white">See Detail</a></button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
