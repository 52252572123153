import React from 'react'
import { useState } from 'react';
import Chart from "react-apexcharts";

const ChartMultiLine = ({r, s, t}) => {  
  let series = [
    {   
        name: r[0],
        data: r[1]
    },
    {
        name: s[0], 
        data: s[1]
    },
    {
        name: t[0], 
        data: t[1]
    },
  ]

  let options = {
    noData: {
      text: 'No Data',
      style: {
        color: "red",
        fontSize: '2rem',
      }
    },
    grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
    },
    colors: ['#3f285e', '#E91E63', '#77B6EA'],
    "chart": {
      id: "basic-bar",
      stacked: false,
      zoom:false,
      "height": 750,
      "type": "line",
      xaxis: {
        type: 'datetime',
      },
    },
    
    xaxis: {
      type: 'category'
    },
    stroke: {
      curve: 'smooth',
    },
    "dataLabels": {
      "enabled": true,
      "offsetX": -6,
      "style": {
        "fontSize": "12px",
        "colors": [
          function ({ seriesIndex,dataPointIndex,  w }) {
            if (r.length < 2) {
              return "green";
            } else {
              if (w.config.series[seriesIndex].data[dataPointIndex].y > 0) {
                return "green";
              } else {
                return "#ff0014";
              }
            }
          },
        ]
      }
    },
  }

 

  return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart" style={{minWidth:'98%'}}>
            <Chart
            options={options}
            series={series}
            type="line"
            width="100%"
            height={300}
            />
          </div>
        </div>
      </div>
  )
}

export default ChartMultiLine