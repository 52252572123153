import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from 'moment';
import Swal from 'sweetalert2'




export default function ExportListStockOpname({time, location, data}) {
  const tableRef = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background:"#c51a1a",
    color:"#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Stock Opname ${new Date().toLocaleDateString('id-ID')}`,
    sheet: "Stock Opname"
  });

  let handleSubmit = () => {
    onDownload()
    
    Toast.fire({
      icon: "success",
      title: "Data has exported",
    });
  } 
  

  return (
    <div>
      <button hidden={data.length == 0} onClick={()=> handleSubmit()} className="btn bg-pallete text-white"><span className="fa fa-download mr-2" /> Export excel </button>
        <table className="table table-bordered datatables" ref={tableRef} style={{display:'none'}}>
            <thead className="thead-light">
            <tr>
                <th colSpan="12" align="center">Area Location : {location}</th>
            </tr>
            <tr>
                <th colSpan="12"   align="center">Time Production : {time}</th>
            </tr>
           
            <tr>
                      <th colSpan={1} rowSpan={2} className="text-center"  style={{ textAlign: "center", verticalAlign: "middle" }}>No</th>
                      <th colSpan={1} rowSpan={2} className="text-center " style={{ textAlign: "center", verticalAlign: "middle" }}>Day / Date</th>
                      <th colSpan={3} rowSpan={1} className="text-center "><b>PAC</b></th>
                      <th colSpan={3} rowSpan={1} className="text-center "><b>Polymer</b></th>
                      <th colSpan={3} rowSpan={1} className="text-center "><b>Na0CI</b></th>
                      <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}>Remarks</th>
                      {/* <th colSpan={1} rowSpan={2} className="text-center" style={{ textAlign: "center", verticalAlign: "middle" }}>Action</th> */}
                    </tr>
                    <tr>
                      <th className="">Input<br />(KG)</th>
                      <th className="">Output<br />(KG)</th>
                      <th className="">Balance<br />(KG)</th>
                      <th className="">Input<br />(KG)</th>
                      <th className="">Output<br />(KG)</th>
                      <th className="">Balance<br />(KG)</th>
                      <th className="">Input<br />(KG)</th>
                      <th className="">Output<br />(KG)</th>
                      <th className="">Balance<br />(KG)</th>
                  
                    </tr>
            </thead>
            <tbody>
                    {data !== 0 && data.map((el, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        <td className="text-center">{moment.utc(el.datetime).local().format('DD/MM/YYYY ')}</td>
                        {/* <td>{moment.utc(el.date_time).local().format('DD/MM/YYYY HH:mm:ss')} <button id="btn_add" type="button"  data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-primary" onClick={()=> getDetailHours(el.id)}>See Detail</button> </td> */}
                        <td className="text-center">{el.chemical_pac_input}</td>
                        <td className="text-center">{el.chemical_pac_output}</td>
                        <td className="text-center">{el.chemical_pac_balance}</td>
                        <td className="text-center">{el.chemical_polymer_input}</td>
                        <td className="text-center">{el.chemical_polymer_output}</td>
                        <td className="text-center">{el.chemical_polymer_balance}</td>
                        <td className="text-center">{el.chemical_naocl_input}</td>
                        <td className="text-center">{el.chemical_naocl_output}</td>
                        <td className="text-center">{el.chemical_naocl_balance}</td>
                        <td>-</td>
                        {/* <td style={{ display: "flex", justifyContent: "center" }}>
                          <button id="btn_edit" type="button" data-toggle="modal" data-target="#exampleModalUpdate" data-action="tambah" className="ml-3 btn btn-sm btn-danger" >Edit Remaks</button>
                          <button id="btn_hours" className="ml-3 btn btn-sm bg-green text-white" ><a href="./gethours/stockopname" className="text-white">See Detail</a></button>
                        </td> */}
                      </tr>
                    ))}
            </tbody>
            {/* <tfoot>
                <tr>
                    <th colSpan={2} className="text-center">Total</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th>23.548</th>
                    <th />
                </tr>
            </tfoot> */}
        </table>
    </div>
  );
}
