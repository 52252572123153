import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../api/base_url";
import DatePicker from "../../components/DatePicker";
import Footer from "../../components/Footer";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Header from "../../components/Header";
import moment from 'moment-timezone';
import ExportListAllSystem from "../../components/ExportExcelAllSystem/ExportListAllSystem";
import ExportListStockOpname from "../../components/ExportExcelStockopname/ExportListStockOpname";
import ReactSpinner from "../../components/ReactSpinner";




const StockOfName = () => {
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#c51a1a",
    color: "#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start = moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

  const navigate = useNavigate();
  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  const [dateRangePickerDisplay, setDateRangePickerDisplay] = useState(`${start}/${end}`);
  const [pending, setPending] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [datetime, setUpdateDatetime] = useState("");
  
  const [pacInput, setUpdatePacInput] = useState("");
  const [pacOutput, setUpdatePacOutput] = useState("");
  const [pacBalance, setUpdatePacBalance] = useState("");
  const [polymerInput, setUpdatePolymerInput] = useState("");
  const [polymerOutput, setUpdatePolymerOutput] = useState("");
  const [polymerBalance, setUpdatePolymerBalance] = useState("");
  const [naoclInput, setUpdateNaoclInput] = useState("");
  const [naoclOutput, setUpdateNaoclOutput] = useState("");
  const [naoclBalance, setUpdateNaoclBalance] = useState("");
  const [remarks, setUpdateRemarks] = useState("");
  const [status, setUpdateStatus] = useState("");
  const { id } = useParams();

  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to Update this data?",
      showCancelButton: true,
      confirmButtonText: "Update",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          datetime: datetime,
          status: status,
          remarks: remarks,
          pac_input: Number(pacInput),
          pac_output: Number(pacOutput),
          pac_balance: Number(pacBalance),
          polymer_input: Number(polymerInput),
          polymer_output: Number(polymerOutput),
          polymer_balance: Number(polymerBalance),
          naocl_input: Number(naoclInput),
          naocl_output: Number(naoclOutput),
          naocl_balance: Number(naoclBalance),
        };
        
        if (pacInput === '' || pacOutput === '' || pacBalance === '' || polymerInput === '' || polymerOutput === '' || polymerBalance === '' || naoclInput === '' || naoclOutput === '' || naoclBalance === '' ) {
            Swal.fire("Data is Required, please fill out the form", "", "error");
          } else {
          API.put(`/report/stock/opname/day/${id}`, payload, { headers })
            .then(({ data }) => {
              navigate(`/reportAnalysis/stockOpname`);
              Swal.fire("User has updated successfully", "", "success");
            })
            .catch((err) => {
              Swal.fire("error from server", "", "error");
            });
        }
      }
    });
  };

  useEffect(() => {
    API.get(`report/stock/opname/day/${id}`, { headers })
    .then(({ data: content }) => {
      setUpdateDatetime(content.data.datetime);
      setUpdatePacInput(content.data.chemical_pac_input);
      setUpdatePacOutput(content.data.chemical_pac_output);
      setUpdatePacBalance(content.data.chemical_pac_balance);
      setUpdatePolymerInput(content.data.chemical_polymer_input);
      setUpdatePolymerOutput(content.data.chemical_polymer_output);
      setUpdatePolymerBalance(content.data.chemical_polymer_balance);
      setUpdateNaoclInput(content.data.chemical_naocl_input);
      setUpdateNaoclOutput(content.data.chemical_naocl_output);
      setUpdateNaoclBalance(content.data.chemical_naocl_balance);
      setUpdateStatus(content.data.status);
      setUpdateRemarks(content.data.remarks);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <div id="wrap">
      <div>
        <Header />
        {/* page comtent */}
        <div className="row container-fluid mt-3">
          <div className="col-lg-12">
            <div className="card">
              <div className="d-flex justify-content-between card-header">
                <h4 className="text-darker">
                  Update Stock Opname
                </h4>
                <div className="form-group">
                  <label className="form-control-label">Date : <span>{ moment(datetime).format("YYYY-MM-DD") }</span></label>
                </div>
              </div><br></br>
              <div className="col-lg-12">
                  <div className="row">
                    {/* PAC */}
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">PAC Input</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={pacInput}
                            onChange={(e)=> setUpdatePacInput(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">PAC Output</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={pacOutput}
                            onChange={(e)=> setUpdatePacOutput(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">PAC Balance</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={pacBalance}
                            onChange={(e)=> setUpdatePacBalance(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Polymer */}
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">Polymer Input</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={polymerInput}
                            onChange={(e)=> setUpdatePolymerInput(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">Polymer Output</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={polymerOutput}
                            onChange={(e)=> setUpdatePolymerOutput(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">Polymer Balance</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={polymerBalance}
                            onChange={(e)=> setUpdatePolymerBalance(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* NAOCL */}
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">NaOCL Input</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={naoclInput}
                            onChange={(e)=> setUpdateNaoclInput(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">NaOCL Output</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={naoclOutput}
                            onChange={(e)=> setUpdateNaoclOutput(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2">
                      <div className="form-group">
                        <label className="form-control-label">NaOCL Balance</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={naoclBalance}
                            onChange={(e)=> setUpdateNaoclBalance(e.target.value)}
                            className="form-control input-sm"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text bg-gray text-white" id="basic-addon2">KG </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button 
                  className="btn btn-warning mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=> handleUpdate()}
                  >
                    Edit
                  </button>
                  <button 
                  className="btn btn-primary mt-5"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=> navigate('/reportAnalysis/stockOpname')}
                  >
                    Cancel
                  </button>
              </div><br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default StockOfName;








