import React, { useState, useEffect } from "react";
import ChartExample from "../../components/ChartExample";
import DatePicker from "../../components/DatePicker";
import Swal from 'sweetalert2'
import BreadCumb from "../../components/BreadCumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import API from "../../api/base_url";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from 'moment-timezone';
import ExportElevated from "../../components/ExportExcelElevatedArea/ExportElevated";
import ReactSpinner from "../../components/ReactSpinner";
import Moment from 'react-moment';



  


const ElevatedTank = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background:"#c51a1a",
    color:"#ffffff",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let start =  moment().startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
  let end = moment().endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")

  const columnsListData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Name Elevated Tower",
      cell: (row) => row.name,
    },
    {
      name: "Level",
      cell: (row) => `${row.level} cm`,
    },
    {
      name: "Flowrate",
      cell: (row) => `${row.flowrate} m3/h` ,
    },
    {
      name: "Totalizer",
      cell: (row) => `${row.totalizer} m3` ,
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
  ];

  const columnsListTotalData = [
    {
      name: "No",
      cell: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tower A",
      cell: (row) => `${row.totalizer_tower_a} m3`,
    },
    {
      name: "Tower B",
      cell: (row) => `${row.totalizer_tower_b} m3`,
    },
    {
      name: "Total",
      cell: (row) => `${row.totalizer_total} m3`,
    },
    {
      name: "Data & Time",
      cell: (row) => <Moment format="DD-MM-YYYY HH:mm:ss">{row.recorded_at}</Moment>,
    },
  ];
  
  const token = localStorage.token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  
  const [site, setSite] = useState([]);
  const [pending, setPending] = useState(true);

 
  
  const [chartTower1LevelMeter, setChartTower1LevelMeter] = useState([])
  const [chartTower1Flowrate, setChartTower1Flowrate] = useState([])
  const [chartTower1Clearator, setChartTower1Clearator] = useState([])
  const [chartTower2LevelMeter, setChartTower2LevelMeter] = useState([])
  const [chartTower2Flowrate, setChartTower2Flowrate] = useState([])
  const [chartTower2Clearator, setChartTower2Clearator] = useState([])
  const [listData, setListData] = useState([])
  const [listTotal, setListTotal] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const handleErrorMessage = (message) => {
    setErrorMessage(message) // Update error message state from the child component
  }




  const [dateRangePickerValue, setDateRangePickerValue] = useState(`${start}/${end}`);
  
  const getSite = () => {
    API.get("utility/master/data/site", { headers })
      .then(({ data: content }) => {
        setSite(content.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const getListData = () => {
    API.get(`report/elevated/${dateRangePickerValue}`, { headers })
      .then(({ data: content }) => {
      setListData(content.data.list_data)
      setListTotal(content.data.list_total)

       let tower1LevelMeterTmps = []
       let tower1FlowrateTmps = []
       let tower1ClearatorTmps = []
       let tower2LevelMeterTmps = []
       let tower2FlowrateTmps = []
       let tower2ClearatorTmps = []

       content.data.tower_1_levelmeter.report_device_value_trend.forEach(el => {
        tower1LevelMeterTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tower_1_flowrate.report_device_value_trend.forEach(el => {
        tower1FlowrateTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tower_1_clearator.report_device_value_trend.forEach(el => {
        tower1ClearatorTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tower_2_levelmeter.report_device_value_trend.forEach(el => {
        tower2LevelMeterTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tower_2_flowrate.report_device_value_trend.forEach(el => {
        tower2FlowrateTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });

       content.data.tower_2_clearator.report_device_value_trend.forEach(el => {
        tower2ClearatorTmps.push({x:moment.utc(el.report_device_value_trend_time).local().format('DD/MM/YYYY HH:mm:ss'), y:el.report_device_value_trend_value})
       });


       setChartTower1LevelMeter(tower1LevelMeterTmps)
       setChartTower1Flowrate(tower1FlowrateTmps)
       setChartTower1Clearator(tower1ClearatorTmps)
       setChartTower2LevelMeter(tower2LevelMeterTmps)
       setChartTower2Flowrate(tower2FlowrateTmps)
       setChartTower2Clearator(tower2ClearatorTmps)

      setPending(false)
      setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
   }
 
  const handleFilterSubmit = () => {
    setChartTower1LevelMeter([])
    setChartTower1Flowrate([])
    setChartTower1Clearator([])
    setChartTower2LevelMeter([])
    setChartTower2Flowrate([])
    setChartTower2Clearator([])
    getListData()
    Toast.fire({
      icon: "success",
      title: "Filter Data Request",
    });
   }
  const setDateRangePickerValueFunc = (val) => {
    setDateRangePickerValue(val)
   }

  useEffect(() => {
    getSite()
    getListData()
  }, [])
  

  return (
    <div id="wrap">
      {/* {isLoading ? <ReactSpinner/>: */}
        <div >
          <Header/>
          <BreadCumb pageRoot="Report & Analisis" name="Elevated Tank" parent="/dashboard" subParent="/dashboard" underPage="Report Analisis" />
          <div className="container-fluid mt--6">
            <div className="card shadowTailwind">
            <div className="col-lg-12 py-4 d-flex">
              <div className="col-lg-6">
                <label className="form-control-label textPallete">Site</label>
                <select
                  className="form-control text-darker"
                  id="channel"
                  name="channel"
                  data-toggle="select"
                  disabled
                  value={1}
                >
                  {site.length === 0 ? (
                    <></>
                  ) : (
                    site.map((el, idx) => (
                      <option value={el.id} key={idx}>
                        {el.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-9">
                    <label className="form-control-label textPallete">Date Range</label>
                    <DatePicker
                      take={(val) => setDateRangePickerValueFunc(val)}
                      setErrorMessage={handleErrorMessage}
                    />
                    {/* Display the error message from the DatePicker */}
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                  </div>
                  <div className="col-lg-3">
                    <label className="form-control-label textPallete">&nbsp;</label> <br />
                    <button
                      className="btn bg-pallete text-white"
                      onClick={() => handleFilterSubmit()}
                      disabled={errorMessage.length > 0}
                    >
                      <span className="fa fa-search mr-2" /> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          {/* filter */}
          
          {/* Page content */}
          <div className="container-fluid">
            <div className="row mt-3">
              {/* TOWER 1 */}
              {/* level meter */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Tower 1 - Level Meter</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartTower1LevelMeter}  />
                  </div>
                </div>
              </div>

              {/* flowrate */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Tower 1 - Flowrate</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartTower1Flowrate}  />
                  </div>
                </div>
              </div>

              {/* clearator */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Tower 1 - Clearator</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartTower1Clearator}  />
                  </div>
                </div>
              </div>

              {/* TOWER 2 */}
              {/* level meter */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Tower 2 - Level Meter</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartTower2LevelMeter}  />
                  </div>
                </div>
              </div>

              {/* flowrate */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Tower 2 - Flowrate</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartTower2Flowrate}  />
                  </div>
                </div>
              </div>

              {/* clearator */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="mb-0 text-red">Tower 2 - Clearator</h4>
                  </div>
                  <div className="col-lg-12" >
                    <ChartExample streaming={chartTower2Clearator}  />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex justify-content-between">
                  <h4 className="text-darker">
                      List Data 
                  </h4>
                  {/* <ExportReservoir time={dateRangePickerValue} location={selectedLocationValue} data={data}/> */}
                </div>
                <DataTableExtensions
                  print={false}
                  export={false}
                  exportHeaders
                  columns={columnsListData}
                  data={listData}
                  filter={false}
                >
                  <DataTable pagination progressPending={pending} />
                </DataTableExtensions>
              </div>

              <div className="col-lg-6">
                <div className="d-flex justify-content-between">
                  <h4 className="text-darker">
                      List Total 
                  </h4>
                  {/* <ExportReservoir time={dateRangePickerValue} location={selectedLocationValue} data={data}/> */}
                </div>
                <DataTableExtensions
                  print={false}
                  export={false}
                  exportHeaders
                  columns={columnsListTotalData}
                  data={listTotal}
                  filter={false}
                >
                  <DataTable pagination progressPending={pending} />
                </DataTableExtensions>
              </div>
            </div>
            
          </div>
        </div>
      {/* } */}
    </div>
  );
};

export default ElevatedTank;
